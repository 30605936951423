import { Component, OnInit, HostBinding, TemplateRef } from '@angular/core';
import { ToastService } from '../toast.service';
@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    host: { '[class.ngb-toasts]': 'true' },
})
export class ToastComponent {
    @HostBinding('class.ngb-toasts') apply = true;

    constructor(public toastService: ToastService) {}

    public isTemplate(toast: any): boolean {
        return toast.textOrTpl instanceof TemplateRef;
    }
}
