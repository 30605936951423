<div class="main">
    <header class="header position-relative" *ngIf="!(navigationVisible$ | async)">
        <nav class="navbar navbar-expand-lg fixed-top py-3 navbar-light">
            <div class="container">
                <div class="nm">
                    <img
                        src="../assets/benx-automotive-logo.png"
                        alt="benx automotive logo"
                    />
                </div>
                <button
                    class="navbar-toggler collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span class="my-1 mx-2 close">X</span>
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div id="navbarSupportedContent" class="collapse navbar-collapse">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item">
                            <a
                                class="nav-link text-uppercase py-lg-0"
                                data-aos="fade-right"
                                data-aos-offset="300"
                                data-aos-easing="ease-in-sine"
                                href="/home"
                                routerLink="/home"
                                data-toggle="collapse"
                                data-target=".navbar-collapse.show"
                                routerLinkActive="text-primary"
                                (click)="scrollToHome(target)"
                                >Home <span class="sr-only">(current)</span></a
                            >
                        </li>
                        <li class="nav-item text-uppercase">
                            <a
                                class="nav-link py-lg-0"
                                (click)="scrollToCarServicesFromNav(target)"
                                data-aos="fade-right"
                                data-aos-offset="300"
                                data-aos-easing="ease-in-sine"
                                href="/car-services"
                                routerLink="/car-services"
                                data-toggle="collapse"
                                data-target=".navbar-collapse.show"
                                routerLinkActive="text-primary"
                                >Services</a
                            >
                        </li>
                        <!-- <li class="nav-item text-uppercase">
                            <a
                                class="nav-link py-0"
                              
                                data-aos="fade-right"
                                data-aos-offset="300"
                                data-aos-easing="ease-in-sine"
                                routerLink="/blog"
                                (click)="scrollToBlog(target)"
                                >Blog</a
                            >
                        </li> -->
                        <li class="nav-item text-uppercase">
                            <a
                                class="nav-link py-lg-0"
                                data-aos="fade-right"
                                data-aos-offset="300"
                                data-aos-easing="ease-in-sine"
                                href="/projects"
                                routerLink="/projects"
                                data-toggle="collapse"
                                data-target=".navbar-collapse.show"
                                routerLinkActive="text-primary"
                                (click)="scrollToProjects(target)"
                                >Projects</a
                            >
                        </li>
                        <li class="nav-item text-uppercase">
                            <a
                                class="nav-link py-lg-0"
                                data-aos="fade-right"
                                data-aos-offset="300"
                                data-aos-easing="ease-in-sine"
                                href="/contact"
                                routerLink="/contact"
                                data-toggle="collapse"
                                data-target=".navbar-collapse.show"
                                routerLinkActive="text-primary"
                                (click)="scrollToContact(target)"
                                >Contact</a
                            >
                        </li>

                        <!--
                        <li class="nav-item text-uppercase">
                            <a
                                class="nav-link"
                               
                                data-aos="fade-right"
                                data-aos-offset="300"
                                data-aos-easing="ease-in-sine"
                                >About</a
                            >
                        </li> -->
                    </ul>
                </div>
            </div>
        </nav>

        <div class="container position-absolute div-center">
            <div class="row" data-aos="fade-down">
                <div class="col-xl-5 col-sm-12 offset-xl-6 text-white">
                    <h1 class="font-weight-bold display-4">
                        Welcome to Benx Automotive!
                    </h1>

                    <div class="off-white my-4">
                        <h5 class="font-weight-normal">
                            We are a group of qualified automotive technicians based in
                            London. We specialise in various fields in the automotive
                            industry.
                        </h5>
                    </div>

                    <div class="d-inline py-4 d-flex text-center">
                        <button
                            type="button"
                            class="btn btn-primary mr-4 flex-fill"
                            href="/car-services"
                            routerLink="/car-services"
                            (click)="scrollToCarServicesFromButton(target)"
                        >
                            Explore
                        </button>

                        <!-- <button
                            type="button"
                            class="btn btn-secondary flex-fill"
                            href="/book-now"
                            routerLink="/book-now"
                            (click)="scrollToBookNow(target)"
                        >
                            Book Now
                        </button> -->
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section>
        <router-outlet #target></router-outlet>
    </section>
</div>
<footer>
    <div class="bg-dark-black fixed-bottom m-n3">
        <div class="container pt-3 pb-4 text-primary">
            <div class="row">
                <div class="col">
                    <div class="text-center">
                        <!-- <a href="https://facebook.com/">
                            <i class="fa fa-facebook-f mr-3 icon"></i>
                        </a> -->
                        <a
                            href="https://www.instagram.com/benxautomotive"
                            target="_blank"
                        >
                            <i class="fa fa-instagram mr-3 icon"></i
                        ></a>

                        <a href="tel:07459853477"
                            ><i class="fa fa-phone mr-3 icon"> </i
                        ></a>

                        <a (click)="whatsapp()">
                            <i class="fa fa-whatsapp mr-3 icon"></i>
                        </a>

                        <a href="mailto:info@benxautomotive@gmail.com"
                            ><i class="fa fa-envelope-o icon"> </i
                        ></a>
                    </div>

                    <div class="mt-2">
                        <h6 class="text-center">
                            <i class="fa fa-copyright" aria-hidden="true"></i>
                            Benx Automotive 2020 - All rights reserved
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
