<app-toast aria-live="polite" aria-atomic="true"></app-toast>

<div class="container py-5">
    <div class="row">
        <div class="col">
            <div class="card shadow-lg mb-8 bg-white rounded card-border-none">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 col-sm-12 p-0">
                            <div class="contact-img">
                                <div class="d-flex flex-column text-white h-100">
                                    <div
                                        class="
                                            d-flex
                                            flex-column
                                            my-auto
                                            align-items-center
                                            py-4
                                        "
                                    >
                                        <div class="align-items-center">
                                            <div class="d-flex">
                                                <span>
                                                    <i
                                                        class="
                                                            fa fa-map-marker
                                                            mr-3
                                                            fa-1-5x
                                                        "
                                                    ></i>
                                                </span>

                                                <div class="d-flex flex-column">
                                                    <h6>Address</h6>
                                                    <p
                                                        class="
                                                            text-warning
                                                            font-weight-bold
                                                        "
                                                    >
                                                        London, United Kingdom
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <span>
                                                    <i
                                                        class="fa fa-phone mr-3 fa-1-5x"
                                                    ></i>
                                                </span>

                                                <div class="d-flex flex-column">
                                                    <h6>Phone</h6>
                                                    <p
                                                        class="
                                                            text-warning
                                                            font-weight-bold
                                                        "
                                                    >
                                                        +447459853477
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <span>
                                                    <i
                                                        class="
                                                            fa fa-envelope
                                                            mr-3
                                                            fa-1-5x
                                                        "
                                                    ></i>
                                                </span>

                                                <div class="d-flex flex-column">
                                                    <h6>Email</h6>
                                                    <p
                                                        class="
                                                            text-warning
                                                            font-weight-bold
                                                            mb-0
                                                        "
                                                    >
                                                        benxautomotive@gmail.com
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <div class="p-4">
                                <h4 class="text-center pb-2">Send Us A Message</h4>
                                <div class="p-2">
                                    <form [formGroup]="form" (ngSubmit)="submit()">
                                        <div class="form-group">
                                            <label for="nameText">Name</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="nameText"
                                                [formControl]="form.get('nameText')"
                                            />
                                            <!-- <app-error-message
                                                [displayError]="isFieldValid('nameText')"
                                                errorMsg="Please enter your name"
                                            >
                                            </app-error-message> -->
                                            <div *ngIf="isFieldValid('nameText')">
                                                <small
                                                    *ngIf="
                                                        form.get('nameText').errors
                                                            ?.required
                                                    "
                                                    class="text-danger"
                                                >
                                                    <i
                                                        class="
                                                            fa fa-exclamation-triangle
                                                            mr-1
                                                            text-primary
                                                        "
                                                    ></i
                                                    >Please enter your name</small
                                                >
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="emailText">Email</label>
                                            <input
                                                type="email"
                                                class="form-control"
                                                id="emailText"
                                                [formControl]="form.get('emailText')"
                                            />
                                            <!-- <app-error-message
                                                [displayError]="isFieldValid('emailText')"
                                                [errorRequiredMessage]="
                                                    errorsRequiredValidation('emailText')
                                                "
                                                [errorPatternMessage]="
                                                    errorsPatternValidation('emailText')
                                                "
                                                errorRequired="Please enter your email"
                                                errorPattern="Please enter an email valid"
                                            >
                                            </app-error-message> -->

                                            <div *ngIf="isFieldValid('emailText')">
                                                <small
                                                    *ngIf="
                                                        form.get('emailText').errors
                                                            ?.required
                                                    "
                                                    class="text-danger"
                                                >
                                                    <i
                                                        class="
                                                            fa fa-exclamation-triangle
                                                            mr-1
                                                            text-primary
                                                        "
                                                    ></i
                                                    >Please enter your email
                                                    address</small
                                                >
                                                <small
                                                    *ngIf="
                                                        form.get('emailText').errors
                                                            ?.pattern
                                                    "
                                                    class="text-danger"
                                                    ><i
                                                        class="
                                                            fa fa-exclamation-triangle
                                                            mr-1
                                                            text-primary
                                                        "
                                                    ></i>
                                                    Please provide a valid email
                                                    address</small
                                                >
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="messageTextarea">Message</label>
                                            <textarea
                                                class="form-control"
                                                id="messageTextarea"
                                                rows="5"
                                                [formControl]="
                                                    form.get('messageTextarea')
                                                "
                                            ></textarea>
                                            <!-- <app-error-message
                                                [displayError]="
                                                    isFieldValid('messageTextarea')
                                                "
                                                errorMsg="Please enter your message"
                                            >
                                            </app-error-message> -->
                                            <div *ngIf="isFieldValid('messageTextarea')">
                                                <small
                                                    *ngIf="
                                                        form.get('messageTextarea').errors
                                                            ?.required
                                                    "
                                                    class="text-danger"
                                                >
                                                    <i
                                                        class="
                                                            fa fa-exclamation-triangle
                                                            mr-1
                                                            text-primary
                                                        "
                                                    ></i
                                                    >Please enter your message</small
                                                >
                                            </div>
                                        </div>
                                        <div class="text-center pt-3">
                                            <button type="submit" class="btn btn-primary">
                                                Send Message
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
