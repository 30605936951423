import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
    constructor(private meta: Meta, private title: Title) {}

    ngOnInit(): void {
        this.title.setTitle('Benx Automotive');

        this.meta.addTags([
            {
                name: 'description',
                content: 'Benx Automotive',
            },
            {
                name: 'author',
                content: 'Benx Automotive',
            },
            {
                name: 'keywords',
                content:
                    'Benx Automotive, Ben-X Automotive, Ben-X Automotive 2020, Home Page, qualified automotive technicians, automotive industry',
            },
        ]);
    }
}
