<div class="container pt-3 pb-6">
    <div class="row">
        <div class="col"><h1 class="text-center py-5">My Projects</h1></div>
    </div>
    <div class="row pb-4">
        <div class="col middle" *ngIf="loading === false">
            <app-loading-spinner></app-loading-spinner>
        </div>

        <ng-container *ngIf="loading === true">
            <div
                class="col-lg-6 d-flex flex-column flex-xl-row flex-lg-row pb-5"
                *ngFor="let i of ids"
            >
                <div class="embed-responsive embed-responsive-4by3">
                    <iframe
                        class="embed-responsive-item"
                        [src]="i.idUrl | safe"
                        width="600"
                        height="600"
                        frameborder="0"
                        scrolling="no"
                        allowtransparency="true"
                    ></iframe>
                </div>
            </div>

            <div
                class="col-lg-6 d-flex flex-column flex-xl-row flex-lg-row pb-5"
                *ngFor="let i of scaledVideo"
            >
                <div class="embed-responsive embed-responsive-1by1">
                    <iframe
                        class="embed-responsive-item"
                        [src]="i.idUrl | safe"
                        width="600"
                        height="600"
                        frameborder="0"
                        scrolling="no"
                        allowtransparency="true"
                    ></iframe>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="row pt-3 mb-8 text-center">
        <div class="col">
            <p class="text-muted">
                You can find more projects on our instagram platform.
            </p>
        </div>
    </div>
</div>
