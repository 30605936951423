<div class="container py-8">
    <div class="row">
        <div class="col-lg-6 p-md-5 p-4">
            <h2 data-aos="fade-right" data-aos-easing="ease-in-sine">
                Check out my projects here!
            </h2>
        </div>
        <div class="col-lg-4 offset-lg-2 p-md-5 p-4">
            <a
                type="button"
                class="btn btn-primary btn-lg"
                data-aos="fade-left"
                data-aos-easing="ease-in-sine"
                href="/projects"
                routerLink="/projects"
            >
                my projects!</a
            >
        </div>
    </div>
</div>
<!-- <ng-adsense [pageLevelAds]="true"></ng-adsense> -->

<div class="bg-dark-black py-8">
    <div class="d-flex flex-lg-row flex-column justify-content-between text-center">
        <div class="d-flex flex-column p-5" data-aos="flip-left" data-aos-offset="300">
            <i class="fa fa-calendar fa-3x text-primary" aria-hidden="true"></i>
            <div class="mt-3">
                <h4 class="text-white">Booking from your home or office</h4>
            </div>
        </div>
        <div class="d-flex flex-column p-5" data-aos="flip-left" data-aos-offset="300">
            <i class="fa fa-wrench fa-3x text-primary" aria-hidden="true"></i>
            <div class="mt-3">
                <h4 class="text-white">One year repairs and parts warranty</h4>
            </div>
        </div>
        <div class="d-flex flex-column p-5" data-aos="flip-left" data-aos-offset="300">
            <i class="fa fa-users fa-3x text-primary" aria-hidden="true"></i>
            <div class="mt-3">
                <h4 class="text-white">
                    Fully qualified automotive technicians and mechanics
                </h4>
            </div>
        </div>
        <div class="d-flex flex-column p-5" data-aos="flip-left" data-aos-offset="300">
            <i class="fa fa-gbp fa-3x text-primary" aria-hidden="true"></i>
            <div class="mt-3">
                <h4 class="text-white">Upto 70% cheaper than other franchise dealers</h4>
            </div>
        </div>
    </div>
</div>

<!-- <div class="container py-8">
    <div class="row" data-aos="zoom-in-right" data-aos-offset="300">
        <div class="col text-center">
            <h1 class="pb-4 h1-heading">Book a trusted automotive technician/mechanic</h1>
        </div>
    </div>
    <div class="row" data-aos="zoom-in-left" data-aos-offset="300">
        <div class="col-lg-4 mb-4">
            <div class="card h-100 shadow border-0 hover-primary">
                <img
                    src="../assets/repairs.jpg"
                    class="w-100 h-60"
                    alt="Enquire about services"
                />
                <div class="card-body">
                    <h4 class="card-text">Enquire about services</h4>
                </div>
            </div>
        </div>
        <div class="col-lg-4 mb-4">
            <div class="card h-100 shadow border-0 hover-primary">
                <img
                    src="../assets/calendar.jpg"
                    class="w-100 h-60"
                    alt="Set up appointment"
                />
                <div class="card-body">
                    <h4 class="card-text">Set up appointment</h4>
                </div>
            </div>
        </div>
        <div class="col-lg-4 mb-4">
            <div class="card h-100 shadow border-0 hover-primary">
                <img src="../assets/done.jpg" class="w-100 h-60" alt="booking complete" />
                <div class="card-body">
                    <h4 class="card-text">Booking complete!</h4>
                </div>
            </div>
        </div>
    </div>
    <div class="row" data-aos="zoom-out-right" data-aos-offset="300">
        <div class="col text-center pt-4">
            <button type="submit" class="btn btn-primary btn-lg" routerLink="/book-now">
                Book Now
            </button>
        </div>
    </div>
</div> -->
<!-- <div class="bg-light py-6">
    <div class="container">
        <div class="row">
            <div class="col d-inline p-5 d-flex justify-content-around">
                <span class="fa-stack fa-4x">
                    <i class="fa fa-circle fa-stack-2x icon-background"></i>
                    <i class="fa fa-facebook-f fa-stack-1x" aria-hidden="true"></i>
                </span>
                <span class="fa-stack fa-4x">
                    <i class="fa fa-circle fa-stack-2x icon-background"></i>
                    <i class="fa fa-instagram fa-stack-1x" aria-hidden="true"></i>
                </span>

                 <span class="fa-stack fa-4x">
                    <i class="fa fa-circle fa-stack-2x icon-background"></i>
                    <i class="fa fa-envelope-o fa-stack-1x" aria-hidden="true"></i>
                </span>
            </div>
        </div>
    </div>
</div> -->
<div class="bg-bright-red">
    <div class="container pt-8 pb-12 mb-2">
        <div class="row">
            <div class="col-lg-6 p-md-5 p-4">
                <h2
                    class="text-white"
                    data-aos="fade-right"
                    data-aos-easing="ease-in-sine"
                >
                    We are ready! Let's work
                </h2>
            </div>
            <div class="col-lg-4 offset-lg-2 p-md-5 p-4">
                <button
                    type="button"
                    data-aos="fade-left"
                    data-aos-easing="ease-in-sine"
                    class="btn btn-secondary"
                    href="/contact"
                    routerLink="/contact"
                >
                    Contact us!
                </button>
            </div>
        </div>
    </div>
</div>

<!-- <div class="container pt-5 pb-8 text-center">
    <div class="row">
        <div class="col-12">
            <p class="mb-1">
                This page is <b>under construction</b> so please call us on
                <b><a href="tel:+447466868070"> +447466868070</a></b> for more details.
            </p>
            <p class="mb-0">
                <small class="text-muted"> Images from Unsplash </small>
            </p>
        </div>
    </div>
</div> -->
