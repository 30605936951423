import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss'],
})
export class BlogComponent {}

// https://loiane.com/2017/08/angular-reactive-forms-trigger-validation-on-submit/
// https://www.digitalocean.com/community/tutorials/angular-reactive-forms-introduction
