import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
declare var $: any;

@Component({
    selector: 'app-car-services',
    templateUrl: './car-services.component.html',
    styleUrls: ['./car-services.component.scss'],
})
export class CarServicesComponent implements OnInit {
    constructor(private meta: Meta, private title: Title) {}

    remappingP1 = `Remapping a car is a process that involves adjusting the values in your vehicle's Electronic Control Unit(ECU).`;
    remappingP2 = `Electronic Control Unit(ECU) is a component in the vehicle that basically controls a modern-day engine.
  It processes inputs from sensors and controls almost everything from ignition timing, fuel injection, EGR(exhaust gas recirculation) flow, DPF(Diesel particulate filter), etc. to allow optimal performance of the engine. Remapping basically means making changes to the engine ECU to improve the overall performance of the car. After the remap, you will notice an increase in performance as well as an increase in fuel efficiency, depending on the type of remapping carried out.
  `;
    remappingP3 = `Most remaps consist of stage 1, stage 2, stage 3, stage 4, and so on.
  If the vehicle is stock with no modifications it is advisable to do a stage 1 remap to get the most out of the engine. At Benx-Automotive we offer stage 1, stage 2, stage 3 remaps.`;

    vehicleMaintenance = [
        'Full Service with video of inspection',
        'Interim Service',
        'Timing Belt/Chains',
        'Clutches',
        'Engine Repairs',
        'Suspension Repairs',
        'Wheel Alignment',
    ];

    ngOnInit() {
        this.title.setTitle('Benx Automotive | Car Services Page');

        // this.meta.updateTag({
        //     name: 'description',
        //     content: 'Benx Automotive Car Services Page',
        // });

        this.meta.addTags([
            {
                name: 'description',
                content:
                    'Benx Automotive Car Services Page. We provide the following services: BMW Remapping and other Remapping , Ambient Lighting , Vehicle Maintenance , Diagnostics , Window Tinting , Coding , BMW and VAG Coding,  Electrical and mechanical Diagnostics',
            },
            {
                name: 'author',
                content: 'Benx Automotive',
            },
            {
                name: 'keywords',
                content:
                    'Benx Automotive, Ben-X Automotive, Ben-X Automotive 2020, Remapping, Car Services, Ambient Lighting, Vehicle Maintenance, Diagnostics, Window Tinting, Coding, BMW and VAG Coding,  Electrical and mechanical Diagnostics',
            },
        ]);
    }
}
