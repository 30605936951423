import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { Observable } from 'rxjs/internal/Observable';
import { filter, map } from 'rxjs/operators';
declare var $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    isBrowser;
    navigationVisible$: Observable<boolean>;

    constructor(
        private router: Router,
        private route: ActivatedRoute, // private windowService: WindowService
        @Inject(PLATFORM_ID) private platformId: string
    ) {
        this.navigationVisible$ = this.router.events.pipe(
            filter((e) => e instanceof NavigationEnd),
            map((e) => {
                return route.root.firstChild.snapshot.data.error;
            })
            //   tap(console.log)
        );

        // this.router.events.subscribe((event: Event) => {
        //   switch (true) {
        //     case event instanceof NavigationEnd: {
        //       console.log(event);

        //       break;
        //     }
        //     default: {
        //       break;
        //     }
        //   }
        // });
    }

    whatsapp() {
        window.location.href =
            'https://api.whatsapp.com/send?phone=07459853477&amp;text=Hi,%2we%20use%20ToChat.Be';
    }

    ngOnInit(): void {
        this.isBrowser = isPlatformBrowser(this.platformId);
        AOS.init({
            duration: 1000,
        });
        // tslint:disable-next-line:only-arrow-functions
        if (this.isBrowser) {
            $(window).on('scroll', function () {
                if ($(window).scrollTop() > 10) {
                    $('.navbar').addClass('active');
                } else {
                    $('.navbar').removeClass('active');
                }
            });
        }
    }

    scrollToCarServicesFromNav(el: HTMLElement) {
        this.scroll(el, 0, -130);
    }

    scrollToCarServicesFromButton(el: HTMLElement) {
        el.scrollIntoView({ block: 'start', inline: 'start' });
    }

    scrollToContact(el: HTMLElement) {
        this.scroll(el, 0, -130);
    }

    scrollToHome(el: HTMLElement) {
        this.scroll(el, 0, -850);
    }

    scrollToBookNow(el: HTMLElement) {
        el.scrollIntoView({ block: 'start', inline: 'start' });
    }

    scrollToProjects(el: HTMLElement) {
        this.scroll(el, 0, -130);
    }

    scroll(el: HTMLElement, no1: number, no2: number) {
        el.scrollIntoView({ block: 'start', inline: 'start' }); // if property behaviour is added then it doesn't work
        this.scrollBySection(no1, no2);
    }

    scrollBySection(number1: number, number2: number) {
        window.scrollBy(number1, number2);
    }
}
