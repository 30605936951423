import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ContactsApiService } from '../contacts-api.service';
import { ToastService } from '../toast.service';
@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
    form: FormGroup;
    formSubmitAttempt: boolean;

    constructor(
        private contactsService: ContactsApiService,
        public toastService: ToastService,
        private meta: Meta,
        private title: Title
    ) {}

    ngOnInit(): void {
        this.title.setTitle('Benx Automotive | Contact Page');

        // this.meta.updateTag({
        //     name: 'description',
        //     content: 'Benx Automotive Contact Page',
        // });

        this.meta.addTags([
            {
                name: 'description',
                content: 'Benx Automotive Contact Page',
            },
            {
                name: 'author',
                content: 'Benx Automotive',
            },
            {
                name: 'keywords',
                content:
                    'Benx Automotive, Ben-X Automotive, Ben-X Automotive 2020, Contact Page',
            },
        ]);

        //  this.contactsService.getContacts().subscribe((x) => console.log(x));
        this.form = new FormGroup({
            nameText: new FormControl(null, Validators.required),
            emailText: new FormControl(null, [
                Validators.required,
                Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
            ]),
            messageTextarea: new FormControl(null, Validators.required),
        });
    }

    errorsRequiredValidation(control: string) {
        return this.form.get(control).errors?.required;
    }

    errorsPatternValidation(control: string) {
        return this.form.get(control).errors?.pattern;
    }

    submit() {
        this.formSubmitAttempt = true;

        if (this.form.valid) {
            const updateRequest = {
                name: this.form.value.nameText,
                email: this.form.value.emailText,
                message: this.form.value.messageTextarea,
            };

            this.contactsService
                .postContactsMessageInfo(updateRequest)
                .subscribe((d) => {});

            this.form.reset();
            this.toastService.show('You have successfully sent a message', {
                classname: 'bg-success text-light',
                delay: 2000,
            });
        } else {
            this.validateAllFormFields(this.form); //{7}
        }
    }

    isFieldValid(control: string) {
        return (
            this.form.get(control).invalid && this.form.get(control).touched
            // ||    this.form.get(control).dirty
        );
    }

    validateAllFormFields(formGroup: FormGroup) {
        //{1}
        Object.keys(formGroup.controls).forEach((field) => {
            //{2}
            const control = formGroup.get(field); //{3}
            if (control instanceof FormControl) {
                //{4}
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                //{5}
                this.validateAllFormFields(control); //{6}
            }
        });
    }
}
