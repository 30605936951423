<div *ngIf="displayError">
    <div class="text-primary d-inline">
        <i class="fa fa-exclamation-triangle mr-1" *ngIf="errorRequiredMessage"></i
        >{{ errorRequired }}

        <i class="fa fa-exclamation-triangle mr-1" *ngIf="errorPatternMessage"></i
        >{{ errorPattern }}
    </div>
</div>
<!-- 
not using at the moment -->
