export const environment = {
    production: true,
    firebaseConfig: {
        apiKey: 'AIzaSyDYiiERkX06Epsyfo1bbszFTPJFGIFUOXw',
        authDomain: 'benx-automotive.firebaseapp.com',
        databaseURL: 'https://benx-automotive.firebaseio.com',
        projectId: 'benx-automotive',
        storageBucket: 'benx-automotive.appspot.com',
        messagingSenderId: '348385544778',
        appId: '1:348385544778:web:0de9901f396357bc2734b3',
        measurementId: 'G-917L2H21XV',
    },
};
