import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ContactsApiService, Projects } from '../contacts-api.service';
@Component({
    selector: 'app-projects',
    templateUrl: './projects.component.html',
    styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {
    // ids$: Observable<string[]>;
    ids: Projects[];
    loading: boolean = false;
    test: string;
    scaledVideo: Projects[];

    constructor(
        private service: ContactsApiService,
        private meta: Meta,
        private title: Title
    ) {
        this.title.setTitle('Benx Automotive | Projects Page');
    }

    ngOnInit(): void {
        this.test = '//instagram.com/p/COVPpUEJdue/embed/';

        this.service.getProjects().subscribe((d) => {
            this.ids = d.filter((x) => x.flag !== true);
            this.scaledVideo = d.filter((x) => x.flag === true);
            this.loading = true;

            // this.meta.updateTag({
            //     name: 'description',
            //     content: 'Benx Automotive Projects Page',
            // });

            this.meta.addTags([
                {
                    name: 'description',
                    content: 'Benx Automotive Projects Page',
                },
                {
                    name: 'author',
                    content: 'Benx Automotive',
                },
                {
                    name: 'keywords',
                    content:
                        'Benx Automotive, Ben-X Automotive, Ben-X Automotive 2020, projects, qualified automotive technicians, automotive industry',
                },
            ]);
        });

        // this.ids$ = this.service.getProjects().pipe(map((ids) =>{
        //     this.loading = true;
        // }));
        //   *ngFor="let id of ids$ | async"
    }
}
