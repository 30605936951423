import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-error-message',
    templateUrl: './error-message.component.html',
    styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent implements OnInit {
    @Input() errorRequiredMessage: string;
    @Input() errorPatternMessage: string;

    @Input() errorRequired: string;
    @Input() errorPattern: string;
    @Input() displayError: boolean;
    constructor() {}

    ngOnInit(): void {}
}
