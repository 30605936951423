import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { AdsenseModule } from 'ng2-adsense/adsense.module';
import { AdsenseModule } from 'ng2-adsense';
import { SafePipe } from '../app/safe.pipe';
import { environment } from '../environments/environment.prod';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BlogComponent } from './blog/blog.component';
import { BooknowComponent } from './booknow/booknow.component';
import { CarServicesComponent } from './car-services/car-services.component';
import { ContactComponent } from './contact/contact.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { HomeComponent } from './home/home.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { ProjectsComponent } from './projects/projects.component';
import { ToastComponent } from './toast/toast.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        CarServicesComponent,
        BlogComponent,
        NotfoundComponent,
        ErrorMessageComponent,
        ContactComponent,
        ToastComponent,
        ProjectsComponent,
        BooknowComponent,
        SafePipe,
        LoadingSpinnerComponent,
    ],
    imports: [
        AdsenseModule.forRoot({
            adClient: 'ca-pub-5527970999743127',
        }),
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFirestoreModule,
        HttpClientModule,
        NgbModule,
        CommonModule,
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
