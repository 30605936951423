<div>
    <div class="container pt-5 pb-8 mb-6 d-none d-md-none d-lg-block" id="car-services">
        <div class="row">
            <div class="col mb-2">
                <h1 class="text-center" data-aos="fade-right">Services</h1>
            </div>
        </div>
        <div class="row pt-4 d-flex flex-row panel">
            <div class="col-lg-4 col-sm-12 d-flex flex-column" data-aos="fade-left">
                <h4 class="mb-1">Remapping</h4>
                <small class="text-muted" class="text-muted"
                    >BMW and other Remapping</small
                >
                <div class="accordion-icons mt-2">
                    <a
                        id="main-car-services"
                        data-toggle="collapse"
                        data-target="#car-services-1"
                        href="#car-services-1"
                        aria-controls="car-services-1"
                        aria-expanded="false"
                    >
                        <p class="font-weight-500">
                            More Information
                            <span
                                class="accordion--icon fa fa-angle-down font-weight-bold"
                            ></span>
                            <span
                                class="accordion--icon fa fa-angle-up font-weight-bold"
                            ></span>
                        </p>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 d-flex flex-column" data-aos="fade-left">
                <h4 class="mb-1">Ambient Lighting</h4>
                <small class="text-muted">Enhances vehicle's interior</small>
                <div class="accordion-icons mt-2">
                    <a
                        id="main-car-services"
                        data-toggle="collapse"
                        data-target="#car-services-2"
                        href="#car-services-2"
                        aria-controls="car-services-2"
                        aria-expanded="false"
                    >
                        <p class="font-weight-500">
                            More Information
                            <span
                                class="accordion--icon fa fa-angle-down font-weight-bold"
                            ></span>
                            <span
                                class="accordion--icon fa fa-angle-up font-weight-bold"
                            ></span>
                        </p>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 d-flex flex-column" data-aos="fade-left">
                <h4 class="mb-1">Vehicle Maintenance</h4>
                <small class="text-muted"
                    >Full service, interim service, timing belt/chain etc.</small
                >
                <div class="accordion-icons mt-2">
                    <a
                        id="main-car-services"
                        data-toggle="collapse"
                        data-target="#car-services-3"
                        href="#car-services-3"
                        aria-controls="car-services-3"
                        aria-expanded="false"
                    >
                        <p class="font-weight-500">
                            More Information
                            <span
                                class="accordion--icon fa fa-angle-down font-weight-bold"
                            ></span>
                            <span
                                class="accordion--icon fa fa-angle-up font-weight-bold"
                            ></span>
                        </p>
                    </a>
                </div>
            </div>
        </div>
        <div class="row py-2">
            <div class="col">
                <div id="car-services-1" class="collapse" data-parent="#car-services">
                    <h4>Remapping</h4>
                    <p>
                        {{ remappingP1 }}
                    </p>

                    <p>
                        {{ remappingP2 }}
                    </p>
                    <p>
                        {{ remappingP3 }}
                    </p>
                    <div>
                        The following features are in the remap:
                        <ul>
                            <li>Raised rev limiter to 7200 rpm</li>
                            <li>
                                Pop and Crackle map customised to user perference
                                (Activated via button or subtle pops and agressive pops
                                with the click of a button)
                            </li>
                            <li>DTC Delete</li>
                            <li>DISA valve delete</li>
                            <li>Post cat sensor delete</li>
                            <li>ABS delete</li>
                            <li>ICV delete</li>
                            <li>
                                E - thermostat opening time programming to reduce coolant
                                temperature
                            </li>
                            <li>Improved throttle response</li>
                            <li>Vanos tweaks on m54B30 engines</li>
                        </ul>
                    </div>
                </div>
                <div id="car-services-2" class="collapse" data-parent="#car-services">
                    <h4>Ambient Lighting</h4>
                    <div>
                        We provide the following types of lighting for your vehicle:
                        <ul>
                            <li>FootWell lights reactive to music</li>
                            <li>Starlight roof</li>
                            <li>
                                Interior Ambient lighting at the dashboard, doors and door
                                handles
                            </li>
                            <li>Underglow lighting for the vehicle</li>
                        </ul>
                    </div>
                </div>
                <div id="car-services-3" class="collapse" data-parent="#car-services">
                    <h4>Vehicle Maintenance</h4>
                    <div>
                        We provide the following types of maintenance for your vehicle:
                        <ul>
                            <li *ngFor="let service of vehicleMaintenance">
                                {{ service }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pt-4 d-flex flex-row panel">
            <div class="col-lg-4 col-sm-12 d-flex flex-column" data-aos="fade-left">
                <h4 class="mb-1">Coding</h4>
                <small class="text-muted">BMW and VAG Coding</small>
                <div class="accordion-icons mt-2">
                    <a
                        id="main-car-services"
                        data-toggle="collapse"
                        data-target="#car-services-5"
                        href="#car-services-5"
                        aria-controls="car-services-5"
                        aria-expanded="false"
                    >
                        <p class="font-weight-500">
                            More Information
                            <span
                                class="accordion--icon fa fa-angle-down font-weight-bold"
                            ></span>
                            <span
                                class="accordion--icon fa fa-angle-up font-weight-bold"
                            ></span>
                        </p>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 d-flex flex-column" data-aos="fade-left">
                <h4 class="mb-1">Window Tinting</h4>
                <small class="text-muted">Window Tinting</small>
                <div class="accordion-icons mt-2">
                    <a
                        id="main-car-services"
                        data-toggle="collapse"
                        data-target="#car-services-6"
                        href="#car-services-6"
                        aria-controls="car-services-6"
                        aria-expanded="false"
                    >
                        <p class="font-weight-500">
                            More Information
                            <span
                                class="accordion--icon fa fa-angle-down font-weight-bold"
                            ></span>
                            <span
                                class="accordion--icon fa fa-angle-up font-weight-bold"
                            ></span>
                        </p>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-12 d-flex flex-column" data-aos="fade-left">
                <h4 class="mb-1">Diagnostics</h4>
                <small class="text-muted">Electrical and mechanical Diagnostics</small>
                <div class="accordion-icons mt-2">
                    <a
                        id="main-car-services"
                        data-toggle="collapse"
                        data-target="#car-services-4"
                        href="#car-services-4"
                        aria-controls="car-services-4"
                        aria-expanded="false"
                    >
                        <p class="font-weight-500">
                            More Information
                            <span
                                class="accordion--icon fa fa-angle-down font-weight-bold"
                            ></span>
                            <span
                                class="accordion--icon fa fa-angle-up font-weight-bold"
                            ></span>
                        </p>
                    </a>
                </div>
            </div>
        </div>
        <div class="row pt-3">
            <div class="col">
                <div id="car-services-4" class="collapse" data-parent="#car-services">
                    <h4>Electrical And Mechanical Diagnostics</h4>
                    <div>
                        We provide the following types of diagnostics:
                        <ul>
                            <li>Vehicle Maintenance Daignosis</li>
                            <li>Electrical And Mechanical Diagnostics</li>
                        </ul>
                        <p>
                            From Electrical to Mechanical to NVH issues, we can diagnose
                            all brands of vehciles
                        </p>
                    </div>
                </div>
                <div id="car-services-5" class="collapse" data-parent="#car-services">
                    <h4>BMW and VAG Coding</h4>
                    <p>
                        Currently offering coding services in BMW and Volkswagen group
                        vehicles. Please request what coding features you require and we
                        will send you an estimate
                    </p>
                    <div>
                        We also provide the following coding services:
                        <ul>
                            <li>Needle Swap</li>
                            <li>Beep on lock/unlock</li>
                            <li>USDM lights</li>
                            <li>Seat belt reminder deactivate</li>
                            <li>Video in motion</li>
                        </ul>
                    </div>
                </div>
                <div id="car-services-6" class="collapse" data-parent="#car-services">
                    <h4>Window Tinting</h4>
                    <p>
                        We offer legal window tints to your vehicle according to your
                        requirement
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="container py-5 d-md-block d-lg-none">
        <div class="row">
            <div class="col">
                <h1 class="text-center">Services</h1>
            </div>
        </div>
        <div class="row pt-4 d-flex flex-row">
            <div class="col-sm-12 d-flex flex-column">
                <h4 class="mb-1">Remapping</h4>
                <small class="text-muted" class="text-muted"
                    >BMW and other Remapping</small
                >
                <div class="accordion-icons mt-2">
                    <a
                        id="main-car-services"
                        data-toggle="collapse"
                        data-target="#car-services-1"
                        href="#car-services-1"
                        aria-controls="car-services-1"
                        aria-expanded="false"
                    >
                        <p class="font-weight-500">
                            More Information
                            <span
                                class="accordion--icon fa fa-angle-down font-weight-bold"
                            ></span>
                            <span
                                class="accordion--icon fa fa-angle-up font-weight-bold"
                            ></span>
                        </p>
                    </a>
                </div>
                <div id="car-services-1" class="collapse">
                    <h4>Remapping</h4>
                    <p>
                        {{ remappingP1 }}
                    </p>

                    <p>
                        {{ remappingP2 }}
                    </p>
                    <p>
                        {{ remappingP3 }}
                    </p>
                    <div>
                        The following features are in the remap:
                        <ul>
                            <li>Raised rev limiter to 7200 rpm</li>
                            <li>
                                Pop and Crackle map customised to user perference
                                (Activated via button or subtle pops and agressive pops
                                with the click of a button)
                            </li>
                            <li>DTC Delete</li>
                            <li>DISA valve delete</li>
                            <li>Post cat sensor delete</li>
                            <li>ABS delete</li>
                            <li>ICV delete</li>
                            <li>
                                E - thermostat opening time programming to reduce coolant
                                temperature
                            </li>
                            <li>Improved throttle response</li>
                            <li>Vanos tweaks on m54B30 engines</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 d-flex flex-column">
                <h4 class="mb-1">Ambient Lighting</h4>
                <small class="text-muted">Enhances vehicle's interior</small>
                <div class="accordion-icons mt-2">
                    <a
                        id="main-car-services"
                        data-toggle="collapse"
                        data-target="#car-services-2"
                        href="#car-services-2"
                        aria-controls="car-services-2"
                        aria-expanded="false"
                    >
                        <p class="font-weight-500">
                            More Information
                            <span
                                class="accordion--icon fa fa-angle-down font-weight-bold"
                            ></span>
                            <span
                                class="accordion--icon fa fa-angle-up font-weight-bold"
                            ></span>
                        </p>
                    </a>
                </div>
                <div id="car-services-2" class="collapse">
                    <h4>Ambient Lighting</h4>
                    <div>
                        We provide the following types of lighting for your vehicle:
                        <ul>
                            <li>FootWell lights reactive to music</li>
                            <li>Starlight roof</li>
                            <li>
                                Interior Ambient lighting at the dashboard, doors and door
                                handles
                            </li>
                            <li>Underglow lighting for the vehicle</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 d-flex flex-column">
                <h4 class="mb-1">Vehicle Maintenance</h4>
                <small class="text-muted"
                    >Full service, interim service, timing belt/chain etc.</small
                >
                <div class="accordion-icons mt-2">
                    <a
                        id="main-car-services"
                        data-toggle="collapse"
                        data-target="#car-services-3"
                        href="#car-services-3"
                        aria-controls="car-services-3"
                        aria-expanded="false"
                    >
                        <p class="font-weight-500">
                            More Information
                            <span
                                class="accordion--icon fa fa-angle-down font-weight-bold"
                            ></span>
                            <span
                                class="accordion--icon fa fa-angle-up font-weight-bold"
                            ></span>
                        </p>
                    </a>
                </div>
                <div id="car-services-3" class="collapse">
                    <h4>Full service, interim service, timing belt/chain etc.</h4>
                    <div>
                        We provide the following types of maintenance for your vehicle:
                        <ul>
                            <li *ngFor="let service of vehicleMaintenance">
                                {{ service }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 d-flex flex-column">
                <h4 class="mb-1">Coding</h4>
                <small class="text-muted">BMW and VAG Coding</small>
                <div class="accordion-icons mt-2">
                    <a
                        id="main-car-services"
                        data-toggle="collapse"
                        data-target="#car-services-4"
                        href="#car-services-4"
                        aria-controls="car-services-4"
                        aria-expanded="false"
                    >
                        <p class="font-weight-500">
                            More Information
                            <span
                                class="accordion--icon fa fa-angle-down font-weight-bold"
                            ></span>
                            <span
                                class="accordion--icon fa fa-angle-up font-weight-bold"
                            ></span>
                        </p>
                    </a>
                </div>
                <div id="car-services-4" class="collapse">
                    <h4>BMW and VAG Coding</h4>
                    <p>
                        Currently offering coding services in BMW and Volkswagen group
                        vehicles. Please request what coding features you require and we
                        will send you an estimate
                    </p>
                    <div>
                        We also provide the following coding services:
                        <ul>
                            <li>Needle Swap</li>
                            <li>Beep on lock/unlock</li>
                            <li>USDM lights</li>
                            <li>Seat belt reminder deactivate</li>
                            <li>Video in motion</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 d-flex flex-column">
                <h4 class="mb-1">Window Tinting</h4>
                <small class="text-muted">Window Tinting</small>
                <div class="accordion-icons mt-2">
                    <a
                        id="main-car-services"
                        data-toggle="collapse"
                        data-target="#car-services-5"
                        href="#car-services-5"
                        aria-controls="car-services-5"
                        aria-expanded="false"
                    >
                        <p class="font-weight-500">
                            More Information
                            <span
                                class="accordion--icon fa fa-angle-down font-weight-bold"
                            ></span>
                            <span
                                class="accordion--icon fa fa-angle-up font-weight-bold"
                            ></span>
                        </p>
                    </a>
                </div>
                <div id="car-services-5" class="collapse">
                    <h4>Window Tinting</h4>
                    <p>
                        We offer legal window tints to your vehicle according to your
                        requirement
                    </p>
                </div>
            </div>
            <div class="col-sm-12 d-flex flex-column mb-5">
                <h4 class="mb-1">Diagnostics</h4>
                <small class="text-muted">Electrical And Mechanical Diagnostics</small>
                <div class="accordion-icons mt-2">
                    <a
                        id="main-car-services"
                        data-toggle="collapse"
                        data-target="#car-services-6"
                        href="#car-services-6"
                        aria-controls="car-services-6"
                        aria-expanded="false"
                    >
                        <p class="font-weight-500">
                            More Information
                            <span
                                class="accordion--icon fa fa-angle-down font-weight-bold"
                            ></span>
                            <span
                                class="accordion--icon fa fa-angle-up font-weight-bold"
                            ></span>
                        </p>
                    </a>
                </div>
                <div id="car-services-6" class="collapse">
                    <h4>Electrical And Mechanical Diagnostics</h4>

                    <div>
                        We provide the following types of diagnostics:
                        <ul>
                            <li>VAG and BMW Diagnostics using specislised equipment</li>
                            <li>
                                Electrical And Mechanical Diagnostics for all other brands
                            </li>
                        </ul>
                        <p>
                            From Electrical to Mechanical to NVH issues, we can diagnose
                            all brands of vehciles
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
