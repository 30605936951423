import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

export interface Projects {
    idUrl: string;
    flag?: boolean;
}

export const ids: Projects[] = [
    {
        idUrl: '//instagram.com/p/CC73rxanhv8/embed/',
    },
    {
        idUrl: '//instagram.com/p/CCK5PYJnB3v/embed/',
    },
    {
        idUrl: '//instagram.com/p/CCuHpHXHlF0/embed/',
    },
    {
        idUrl: '//instagram.com/p/B-jH_qZH0bQ/embed/',
    },
    {
        idUrl: '//instagram.com/p/B8UE4wVnrK5/embed/',
    },
    {
        idUrl: '//instagram.com/p/B3nEp9BnKzS/embed/',
    },
    {
        idUrl: '//instagram.com/p/B-G5mGtH2bb/embed/',
    },
    {
        idUrl: '//instagram.com/p/BxqPcknHNVR/embed/',
    },
    {
        idUrl: '//instagram.com/p/Bv5DLwghUW0/embed/',
    },
    {
        idUrl: '//instagram.com/p/B_fndJXHIPE/embed/',
    },

    {
        idUrl: '//instagram.com/p/CBY3vYDnrhl/embed',
        flag: true,
    },
    {
        idUrl: '//instagram.com/p/CAGhy2bHoog/embed',
        flag: true,
    },
    {
        idUrl: '//instagram.com/p/COVPpUEJdue/embed/',
        flag: true,
    },
];

@Injectable({
    providedIn: 'root',
})
export class ContactsApiService {
    apiUrl = 'https://benx-automotive.firebaseapp.com/api/v1/contacts';

    constructor(private httpClient: HttpClient) {}

    postContactsMessageInfo(arg: any): Observable<any> {
        // const httpOptions = {
        //     headers: new HttpHeaders({
        //         'Content-Type': 'application/json',
        //     }),
        // };

        return this.httpClient.post(this.apiUrl, arg, {
            responseType: 'text',
        });
        // return this.httpClient.post(this.apiUrl, arg, httpOptions);
    }

    getContacts(): Observable<any> {
        return this.httpClient.get(this.apiUrl);
    }

    getProjects(): Observable<Projects[]> {
        return of(ids).pipe(delay(1500));
    }
}
